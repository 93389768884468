import { Medical, Alert, Admin, User } from '@app/api';
import { allRoutes, addCSRFToken, isDefined, coreFn } from '@app/core';

/**
 * @module AlertsListing[UroConnect]
 * @description Display listing of alerts, related to activated monitoring modules
 */

export default {
    initAction: function() {
        let AlertsListingUroConnect = {
            onComponentCreate: (props) => (state, actions) => {
                User.searchTheraflowUser(0, {}, '').then((res) => {
                    if (isDefined(res.data) && (res.data.result.length > 0)) {
                        Admin.getInfo().then((info) => {
                            actions.parseInfo(info.data);
                            actions.getPage();
                        });
                    } else {
                        actions._setState({key: 'alerts', value: []});
                        actions._setState({key: 'noPatients', value: true});
                    }
                });
            },
            /**
            * Redirect when user click on a table row (to survey tab)
            * @function AlertsListing[UroConnect]:redirectToPatient
            * @param {string} surveyid - Id of survey
            */
            redirectToPatient: (surveyid) => (state, actions) => {
                Alert.getSurveyPage(surveyid).then((res) => {
                    window.location.href = addCSRFToken(allRoutes['private.patient.edit'].pathname.replace(':id', res.data.user) + '?sp=survey&page=' + res.data.page + '&survey=' + res.data.name);
                });
            },
            /**
            * Redirect when user click on a table row
            * @function AlertsListing[UroConnect]:parseInfo
            * @param {object} info - Info object, contains informatives data
            */
            parseInfo: (info) => (state, actions) => {
                let parsedInfo = {notAcquitted: 0};
                for (let studyName in info.countUserMedicalStudySurveyAlertTodo) {
                    if (state.selectedSurvey.indexOf(studyName) > -1) {
                        parsedInfo.notAcquitted += +info.countUserMedicalStudySurveyAlertTodo[studyName];
                    }
                }
                actions._setState({key: 'info', value: parsedInfo});
            },
            /**
            * Sort listing, then call [getPage]{@link module:AlertsListing[UroConnect]~AlertsListing[UroConnect]:getPage})
            * @function AlertsListing[UroConnect]:onSortList
            * @param {string} sortby - The sort key
            */
            onSortList: (sortby) => (state, actions) => {
                let sorting = state.sort, newSorting = {sort: {}};
                if (!isDefined(sorting.sort)) {
                    sorting.sort = {};
                }
                if (isDefined(sorting.sort[(sortby)])) {
                    if (sorting.sort[(sortby)] === 1) {
                        newSorting.sort[(sortby)] = -1;
                    } else {
                        newSorting.sort[(sortby)] = 1;
                    }
                } else {
                    newSorting.sort[(sortby)] = 1;
                }
                actions._setState({key: 'sort', value: newSorting});
                actions.getPage();
            },
            /**
            * Set current page, then call [getPage]{@link module:AlertsListing[UroConnect]~AlertsListing[UroConnect]:getPage})
            * @function AlertsListing[UroConnect]:targetPage
            */
            targetPage: (page) => (state, actions) => {
                actions._setState({key: 'page', value: page});
                actions.getPage(page);
            },
            /**
            * Update listing page following current page variable
            * @function AlertsListing[UroConnect]:getPage
            */
            getPage: () => (state, actions) => {
                Medical.getMedicalSurveyAlerts(state.page, {name: state.selectedSurvey, sort: state.sort.sort}).then((res) => {
                    if (isDefined(res.data) && (!isDefined(res.data.code))) {
                        let totalAlerts = null, alerts = res.data;
                        if (isDefined(alerts)) {
                            if (isDefined(alerts.infos)) {
                                totalAlerts = alerts.infos.count;
                            }
                            if (isDefined(alerts.result)) {
                                alerts = alerts.result;
                            }
                        }
                        actions._setState({key: 'totalAlerts', value: totalAlerts});
                        actions._setState({key: 'alerts', value: alerts});
                    }
                });
            },
            /**
            * Parse a patient lname and fname to get a human readable string
            * @param {object} el - object to update
            * @param {object} patient - Patient, containing lname and fname to parse
            * @function AlertsListing[UroConnect]:getPatientName
            */
            getPatientName: ({el, patient}) => (state, actions) => {
                if (isDefined(patient.user)) {
                    patient = patient.user;
                }
                let patientLName = (patient.lname || patient.name.split(' ')[0]);
                let patientFName = (patient.fname || patient.name.split(' ')[1]);
                let patientName = patientLName.substring(0, 1).toUpperCase() + patientFName.substring(0, 1).toUpperCase();
                el.innerHTML = patientName;
            },
            /**
            * Increments current page, then call [getPage]{@link module:AlertsListing[UroConnect]~AlertsListing[UroConnect]:getPage})
            * @function AlertsListing[UroConnect]:nextPage
            */
            nextPage: () => (state, actions) => {
                let newPage = (state.page + 1);
                actions._setState({key: 'page', value: newPage});
                actions.getPage();
            },
            /**
            * Decrement current page, then call [getPage]{@link module:AlertsListing[UroConnect]~AlertsListing[UroConnect]:getPage})
            * @function AlertsListing[UroConnect]:prevPage
            */
            prevPage: () => (state, actions) => {
                let newPage = (state.page - 1);
                actions._setState({key: 'page', value: newPage});
                actions.getPage();
            },
            /**
            * Retrieve form input on the fly from table header survey name filter, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
            * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
            * @function AlertsListing[UroConnect]:retrieveSurveySort
            */
            retrieveSurveySort: (input) => (state, actions) => {
                const { values } = input;
                let newSelectedSurvey = state.selectedSurvey;
                if (newSelectedSurvey.indexOf(values) > -1) {
                    newSelectedSurvey.splice(newSelectedSurvey.indexOf(values), 1);
                } else {
                    newSelectedSurvey.push(values);
                }
                actions._setState({key: 'selectedSurvey', value: newSelectedSurvey});
                if (newSelectedSurvey.length > 0) {
                    actions.getPage();
                }
            },
        }
        AlertsListingUroConnect = {...coreFn, ...AlertsListingUroConnect};
        return AlertsListingUroConnect;
    }
}